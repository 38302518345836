import React from "react";
import { Tab, Tabs } from "@mui/material";

import "./styles.scss";

const StyledTabs = (
  {
    isLoading,
    value,
    onChange,
    tabs
  }
) => {
  return (
    <div className="styled-tabs-wrapper">
      {isLoading ? (
        <div className="tabs-loading-box">
          {new Array(tabs?.length || 4).fill(0).map((_, i) => (
            <div className="tab-placeholder" key={i} />
          ))}
        </div>
      ) : (
        <Tabs
          value={value}
          onChange={(e, newVal) => onChange(newVal)}
          className="styled-tabs"
          scrollButtons={false}
          variant="scrollable"
        >
          {tabs?.map((tab, i) => {
            return (
              <Tab
                key={i}
                value={tab.value}
                className="tab-btn"
                disableRipple
                icon={tab.getIcon && tab.getIcon(tab.value === value ? "var(--primary-color)" : "var(--main-text)")}
                label={tab.label}
                disabled={tab.disabled}
              />
            )
          })}
        </Tabs>
      )}
    </div>
  )
};

export default StyledTabs;
