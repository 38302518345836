import { useState, useEffect, createRef } from "react";

export const useTableResize = (columns) => {
  const [tableColumns, setTableColumns] = useState(columns?.map(val => ({ ...val, ref: createRef() })));
  const [dragData, setDragData] = useState(null);

  useEffect(() => {
    setTableColumns(tableColumns?.map(val => ({
      ...val,
      style: { ...(val.style || {}), width: `${val.ref?.current?.clientWidth}px`, flex: "unset" }
    })));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onMouseMove = e => {
    const clientX = e?.clientX || e?.touches?.[0]?.clientX;
    const width = dragData.clientWidth + (clientX - dragData.startPoint);
    setTableColumns(tableColumns.map(column => {
      if (column.label === dragData.column.label) {
        return {
          ...column,
          style: { ...(column.style || {}), width: `${width}px`, flex: "unset", maxWidth: "unset" }
        }
      }
      return column;
    }))
  }

  const onDocMouseUp = () => {
    setDragData(null);
    const modalEl = document.querySelector(".MuiModal-root");
    if (modalEl) {
      modalEl.style.pointerEvents = "unset";
    }
    document.getElementById("root").style.pointerEvents = "unset";
    document.getElementById("root").style.userSelect = "unset";
    document.body.style.cursor = "unset";
  }

  const onMouseDown = (e, column) => {
    const modalEl = document.querySelector(".MuiModal-root");
    if (modalEl) {
      modalEl.style.pointerEvents = "none";
    }
    document.getElementById("root").style.pointerEvents = "none";
    document.getElementById("root").style.userSelect = "none";
    document.body.style.cursor = "col-resize";
    setDragData({
      startPoint: e?.clientX || e?.touches?.[0]?.clientX,
      column,
      clientWidth: column.ref.current.clientWidth
    });
  }

  useEffect(() => {
    if (dragData) {
      document.addEventListener("mousemove", onMouseMove);
      document.addEventListener("mouseup", onDocMouseUp);
      document.addEventListener("touchmove", onMouseMove);
      document.addEventListener("touchend", onDocMouseUp);
    }
    return () => {
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("mouseup", onDocMouseUp);
      document.removeEventListener("touchmove", onMouseMove);
      document.removeEventListener("touchend", onDocMouseUp);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dragData]);

  return { onMouseDown, tableColumns, dragData };
};
