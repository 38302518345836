import React from "react";
import { Button } from "@mui/material";

import ModalWrapper from "components/ModalWrapper";

import "./styles.scss";

const ConfirmModal = (
  {
    onConfirm,
    open,
    onClose,
    isLoading,
    title,
    subtitle,
    isDelete
  }
) => {
  return (
    <ModalWrapper
      open={open}
      onClose={onClose}
      title={title}
      subtitle={subtitle}
      contentClassName="confirmation-modal"
      withoutCloseBtn
      loading={isLoading}
      onClick={e => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <div className="btn-box">
        <Button onClick={onClose} className="close-btn">
          Cancel
        </Button>
        <Button onClick={onConfirm} className={`confirm-btn ${isDelete ? "delete" : ""}`}>
          {isDelete ? "Delete" : "Confirm"}
        </Button>
      </div>
    </ModalWrapper>
  )
};

export default ConfirmModal;
