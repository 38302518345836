import React, { useEffect, useState } from "react";
import { toast } from 'react-toastify';
import { Button } from "@mui/material";

import { Server } from "services/Server";
import ModalWrapper from "components/ModalWrapper";

import Loader from "../Loader";
import StyledTextField from "../StyledTextField";

import "./styles.scss";

const GeneralLimitsModal = ({ open, onClose }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [serverConfig, setServerConfig] = useState({});
  const [limit, setLimit] = useState(0);
  const [ttlUnit, setTtlUnit] = useState(0);
  const [ttlInterval, setTtlInterval] = useState(0);
  const [storageBytesLimit, setStorageBytesLimit] = useState(0);
  const [periodRequests, setPeriodRequests] = useState(0);

  const isFormDifferent = () => {
    return (
      +limit !== serverConfig.periodRows ||
      +ttlUnit !== serverConfig.ttlRequests ||
      +ttlInterval !== serverConfig.ttl ||
      +storageBytesLimit !== serverConfig.storageBytesLimit ||
      +periodRequests !== serverConfig.periodRequests
    )
  }

  useEffect(() => {
    setIsLoading(true);
    Server
      .getServerConfig()
      .then(res => {
        setServerConfig(res);
        setLimit(res.periodRows);
        setTtlUnit(res.ttlRequests);
        setTtlInterval(res.ttl);
        setStorageBytesLimit(res.storageBytesLimit);
        setPeriodRequests(res.periodRequests);
      })
      .catch(e => {
        console.log(e);
      })
      .finally(() => {
        setIsLoading(false);
      })
  }, []);

  const handleSaveChanges = () => {
    Server
      .postServerConfig({
        "blocked": serverConfig.blocked,
        "ttl": Number(ttlInterval),
        "ttlRequests": Number(ttlUnit),
        "periodRequests": Number(periodRequests),
        "periodRows": Number(limit),
        "storageBytesLimit": Number(storageBytesLimit)
      })
      .then(() => {
        toast.success("Changes were saved successfully");
      })
  };

  return (
    <ModalWrapper
      open={open}
      onClose={onClose}
      title="General limit setting"
      contentClassName="general-limits-modal"
    >
      {isLoading ? <Loader /> : null}
      <div className="form-wrapper">
        <div className="inputs-row">
          <StyledTextField
            type="number"
            label="Credits per month"
            value={limit}
            onChange={e => setLimit(e?.target?.value)}
          />
          <StyledTextField
            type="number"
            label="Requests per month"
            value={periodRequests}
            onChange={e => setPeriodRequests(e?.target?.value)}
          />
        </div>
        <div className="inputs-row">
          <StyledTextField
            type="number"
            className="half"
            label="Records"
            value={ttlUnit}
            onChange={e => setTtlUnit(e?.target?.value)}
          />
          <StyledTextField
            type="number"
            className="half"
            label="RL (Seconds)"
            value={ttlInterval}
            onChange={e => setTtlInterval(e?.target?.value)}
          />
        </div>
        <StyledTextField
          type="number"
          label="Storage Bytes Limit"
          value={storageBytesLimit}
          onChange={e => setStorageBytesLimit(e?.target?.value)}
        />
      </div>

      <div className="controls">
        <Button className="cancel-btn" onClick={onClose}>Cancel</Button>
        <Button className="save-btn" disabled={!isFormDifferent()} onClick={handleSaveChanges}>Save</Button>
      </div>
    </ModalWrapper>
  )
}

export default GeneralLimitsModal;