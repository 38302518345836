import React from "react";

const SortArrow = ({ color, toTop, ...props }) => toTop ? (
  <svg {...props} width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path style={{ transition: "0.3s all" }} d="M4.39043 0.988043C4.19027 0.73784 3.80973 0.73784 3.60957 0.988043L0.649878 4.68765C0.387973 5.01503 0.621059 5.5 1.04031 5.5H6.95969C7.37894 5.5 7.61203 5.01503 7.35012 4.68765L4.39043 0.988043Z" fill={color || "var(--second-text)"}/>
  </svg>
) : (
  <svg {...props} width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path style={{ transition: "0.3s all" }} d="M4.39043 5.01196C4.19027 5.26216 3.80973 5.26216 3.60957 5.01196L0.649878 1.31235C0.387973 0.984966 0.621059 0.5 1.04031 0.5H6.95969C7.37894 0.5 7.61203 0.984966 7.35012 1.31235L4.39043 5.01196Z" fill={color || "var(--second-text)"}/>
  </svg>
);

export default SortArrow;
