import React, { useState } from "react";
import { Button } from "@mui/material";
import { toast } from "react-toastify";

import { Datasets } from "services/Datasets";

import ModalWrapper from "components/ModalWrapper";
import StyledTextField from "components/StyledTextField";
import SvgIcon from "components/SvgIcon";
import ConfirmModal from "components/ConfirmModal";

import CloseIcon from "assets/iconComponents/CloseIcon";

import "./styles.scss";

const GroupItem = ({ data, onRemove }) => {
  return (
    <div className="group-item">
      <SvgIcon svgString={data?.icon} />
      <div className="group-name">{data?.name}</div>
      <Button className="remove-btn" onClick={onRemove}>
        <CloseIcon />
      </Button>
    </div>
  )
};

const CreateDatasetGroupModal = ({ state, setState, setGroups, groupsList }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isRemoveLoading, setIsRemoveLoading] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [values, setValues] = useState({});

  const handleClose = () => {
    setState(false);
    setIsLoading(false);
    setIsRemoveLoading(false);
    setOpenConfirmModal(false);
    setValues({});
  };

  const handleSave = async () => {
    setIsLoading(true);
    try {
      const res = await Datasets.createGroup({
        name: values.name,
        icon: values.icon
      });
      setValues({});
      setGroups([...groupsList, res]);
    } catch (e) {
      toast.error(e?.response?.data?.message || "Something went wrong!");
    } finally {
      setIsLoading(false);
    }
  };

  const handleRemoveGroup = async () => {
    setIsRemoveLoading(true);
    try {
      await Datasets.deleteGroup(selectedGroup?.id);
      setGroups(groupsList?.filter(val => val.id !== selectedGroup?.id));
      setOpenConfirmModal(false);
    } catch (e) {
      toast.error(e?.response?.data?.message || "Something went wrong!");
    } finally {
      setIsRemoveLoading(false);
    }
  };

  return (
    <ModalWrapper
      loading={isLoading}
      open={state}
      onClose={handleClose}
      title="Manage Groups"
      contentClassName="create-group-modal"
    >
      <ConfirmModal
        open={openConfirmModal}
        onClose={() => setOpenConfirmModal(false)}
        title="Delete Group"
        subtitle={<>Are you sure you want to delete <span>{selectedGroup?.name}</span> group?</>}
        onConfirm={handleRemoveGroup}
        isLoading={isRemoveLoading}
        isDelete
      />
      <div className="groups-list">
        {groupsList?.map(val => (
          <GroupItem
            key={val.id}
            data={val}
            onRemove={() => {
              setSelectedGroup(val);
              setOpenConfirmModal(true);
            }}
          />
        ))}
      </div>
      <div className="field-box">
        <div className="modal-section-label">Create Group</div>
        <StyledTextField
          label="Name"
          value={values.name || ""}
          onChange={e => setValues({...values, name: e.target.value})}
        />
        <StyledTextField
          label="SVG Icon"
          value={values.icon || ""}
          onChange={e => setValues({...values, icon: e.target.value})}
          multiline
          rows={4}
        />
      </div>
      <div className="btn-box">
        <Button className="cancel-btn" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          className="save-btn"
          disabled={!values.name || !values.icon}
          onClick={handleSave}
        >
          Create
        </Button>
      </div>
    </ModalWrapper>
  )
};

export default CreateDatasetGroupModal;
