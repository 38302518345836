import React from "react";
import { Pagination as MuiPagination } from "@mui/material";

import { paginationLimitOptions } from "utils/options";

import StyledSelect from "components/StyledSelect";

import "./styles.scss";

const Pagination = ({
  className = "",
  totalPages = 1,
  page = 1,
  items = 0,
  totalItems = 0,
  onChange,
  limit = 20,
  onLimitChange,
  isLoading,
  disabled
}) => {
  return (
    <div className={`pagination-wrapper ${className}`}>
      {isLoading ? (
        <div className="loader-pages" />
      ) : (
        <div className="pages">
          {!!limit && onLimitChange ? (
            <StyledSelect
              disabled={disabled}
              value={limit}
              options={paginationLimitOptions}
              onChange={val => onLimitChange(val)}
            />
          ) : items} of {totalItems}
        </div>
      )}
      <div className="divider" />
      {isLoading ? (
        <div className="loader-controls" />
      ) : (
        <MuiPagination
          disabled={disabled}
          count={totalPages || 1}
          page={page || 1}
          onChange={(e, newValue) => {
            if (newValue !== page && onChange) {
              onChange(newValue)
            }
          }}
        />
      )}
    </div>
  )
};

export default Pagination;
