import React from "react";

const TooltipIcon = () => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.41666 6.40491C6.41666 6.2502 6.47811 6.10183 6.58751 5.99243C6.69691 5.88304 6.84528 5.82158 6.99999 5.82158C7.1547 5.82158 7.30307 5.88304 7.41247 5.99243C7.52186 6.10183 7.58332 6.2502 7.58332 6.40491V9.90491C7.58332 10.0596 7.52186 10.208 7.41247 10.3174C7.30307 10.4268 7.1547 10.4882 6.99999 10.4882C6.84528 10.4882 6.69691 10.4268 6.58751 10.3174C6.47811 10.208 6.41666 10.0596 6.41666 9.90491V6.40491ZM6.99999 3.52966C6.84528 3.52966 6.69691 3.59112 6.58751 3.70052C6.47811 3.80991 6.41666 3.95829 6.41666 4.113C6.41666 4.26771 6.47811 4.41608 6.58751 4.52548C6.69691 4.63487 6.84528 4.69633 6.99999 4.69633C7.1547 4.69633 7.30307 4.63487 7.41247 4.52548C7.52186 4.41608 7.58332 4.26771 7.58332 4.113C7.58332 3.95829 7.52186 3.80991 7.41247 3.70052C7.30307 3.59112 7.1547 3.52966 6.99999 3.52966Z"
      fill="var(--main-text)"/>
    <path fillRule="evenodd" clipRule="evenodd"
          d="M6.99999 1.16663C3.77824 1.16663 1.16666 3.77821 1.16666 6.99996C1.16666 10.2217 3.77824 12.8333 6.99999 12.8333C10.2217 12.8333 12.8333 10.2217 12.8333 6.99996C12.8333 3.77821 10.2217 1.16663 6.99999 1.16663ZM2.33332 6.99996C2.33332 8.23764 2.82499 9.42462 3.70016 10.2998C4.57533 11.175 5.76231 11.6666 6.99999 11.6666C8.23767 11.6666 9.42465 11.175 10.2998 10.2998C11.175 9.42462 11.6667 8.23764 11.6667 6.99996C11.6667 5.76228 11.175 4.5753 10.2998 3.70013C9.42465 2.82496 8.23767 2.33329 6.99999 2.33329C5.76231 2.33329 4.57533 2.82496 3.70016 3.70013C2.82499 4.5753 2.33332 5.76228 2.33332 6.99996Z"
          fill="var(--main-text)"/>
  </svg>
);

export default TooltipIcon;
