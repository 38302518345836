import React, { useEffect, useState } from "react";
import { Button, Popover } from "@mui/material";
import moment from "moment/moment";

import { User } from "../../services/User";

import Loader from "../Loader";
import ConfirmPopover from "../ConfirmPopover";
import StyledTooltip from "components/StyledTooltip";

import "./styles.scss";

const UserSessionsModal = ({ user }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [sessionsList, setSessionsList] = useState([]);
  const [deleteSessionId, setDeleteSessionId] = useState(null);
  const [showDeletePopover, setShowDeletePopover] = useState(null);

  useEffect(() => {
    updateSessionsList();
  }, []);

  const updateSessionsList = async () => {
    try {
      setIsLoading(true);
      const res = await User.getSessionsList(user.id);
      setSessionsList(res);
    } catch (e) {
      console.log(e)
    } finally {
      setIsLoading(false);
    }
  }

  const deleteUserSession = sessionId => {
    setIsLoading(true);
    User
      .deleteSession(user.id, sessionId)
      .catch(e => console.log(e))
      .finally(() => {
        updateSessionsList();
        setDeleteSessionId(null);
        setShowDeletePopover(null);
        setIsLoading(false);
      });
  }

  return (
    <div className={`user-sessions-modal ${isLoading ? "loading" : ""}`}>

      <div className="headline">Sessions</div>

      <div className="sessions-list">
        <div className="scroll-wrapper">
          {[...sessionsList, ...sessionsList, ...sessionsList, ...sessionsList, ...sessionsList, ...sessionsList, ...sessionsList, ].map(session => (
            <div className="session-item" key={session.id}>
              <div className="text-box">
                <div className="title">{session.id}</div>
                <StyledTooltip title={session.userAgent}>
                  <div className="subtitle">
                    {session.userAgent}
                  </div>
                </StyledTooltip>
                <div className="text">
                  {session.ip}
                  <div className="divider"></div>
                  {moment(session.updatedAt).format("MMM DD, YYYY, hh:mm a")}
                </div>
              </div>
              <div className="controls-box">
                <Button
                  className="remove-btn"
                  onClick={e => {
                    setDeleteSessionId(session.id);
                    setShowDeletePopover(e.currentTarget);
                  }}
                >
                  End session
                </Button>
              </div>
            </div>
          ))}
        </div>

        <Popover
          open={!!showDeletePopover}
          anchorEl={showDeletePopover}
          onClose={() => setShowDeletePopover(null)}
        >
          <ConfirmPopover
            onDismiss={() => {
              setDeleteSessionId(null);
              setShowDeletePopover(null);
            }}
            onSuccess={() => deleteUserSession(deleteSessionId)}
          />
        </Popover>
      </div>
    </div>
  )
}

export default UserSessionsModal;