import * as React from "react";
import { Helmet } from "react-helmet";
import { Routes, Route, Navigate } from "react-router-dom";

import Users from "./pages/AppWrapper/Users";
import Dataset from "./pages/AppWrapper/Dataset";
import AppWrapper from "./pages/AppWrapper";
import Login from "./pages/Login";
import NoMatch from "./pages/NoMatch";
import PrivateRoute from "./PrivateRoute";
import { DATASETS, LOGIN, USERS } from "pathnameVariables";

export default function App() {
  return (
    <div className="app">
      <Helmet>
        <title>Home - Data Intelligence</title>
        <description>Home - Data Intelligence</description>
      </Helmet>
      <Routes>
        <Route path="/" element={<PrivateRoute component={AppWrapper} />}>
          <Route path={USERS} element={<Users />} />
          <Route path={DATASETS} element={<Dataset />} />
          <Route path="/" element={<Navigate to={USERS} />} />
        </Route>
        <Route path={LOGIN} element={<Login />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </div>
  );
}
