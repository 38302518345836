import React, {useEffect, useState} from "react";
import { Button } from "@mui/material";

import { Datasets } from "services/Datasets";

import ModalWrapper from "components/ModalWrapper";
import StyledTextField from "components/StyledTextField";

import "./styles.scss";

const EditDatasetModal = ({ state, setState, datasetData, setDatasetData }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [values, setValues] = useState(datasetData);

  useEffect(() => {
    if (state) {
      setValues(datasetData);
    }
  }, [state, datasetData]);

  const handleClose = () => {
    setState(false);
    setIsLoading(false);
  };

  const handleSave = async () => {
    setIsLoading(true);
    try {
      const res = await Datasets.updateDataset(datasetData.id, {
        name: values.name,
        ...(values.description ? { description: values.description } : {}),
        ...(values.icon ? { icon: values.icon } : {})
      });
      handleClose();
      setDatasetData(res);
    } catch (e) {

    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ModalWrapper loading={isLoading} open={state} onClose={handleClose} title="Edit Dataset"
                  contentClassName="dataset-edit-modal">
      <div className="field-box">
        <StyledTextField
          label="Name"
          value={values?.name || ""}
          onChange={e => setValues({...values, name: e.target.value})}
        />
        <StyledTextField
          label="Description"
          value={values?.description || ""}
          onChange={e => setValues({...values, description: e.target.value})}
        />
        <StyledTextField
          label="SVG Icon"
          value={values?.icon || ""}
          onChange={e => setValues({...values, icon: e.target.value})}
          multiline
          rows={4}
        />
      </div>
      <div className="btn-box">
        <Button className="cancel-btn" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          className="save-btn"
          disabled={!values?.name}
          onClick={handleSave}
        >
          Save Changes
        </Button>
      </div>
    </ModalWrapper>
  )
};

export default EditDatasetModal;
