import React, { useState, useEffect } from "react";
import StyledSwitcher from "components/StyledSwitcher";
import {Datasets} from "../../../../services/Datasets";
import {toast} from "react-toastify";


const SwitchPublic = ({ data, onUpdateRow }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [checked, setChecked] = useState(!!data?.isPublic);

  useEffect(() => {
    setChecked(!!data?.isPublic);
  }, [data]);

  const handleChange = async e => {
    setChecked(e.target.checked);
    const requestData = {
      format: data.format,
      isPublic: e.target.checked,
      name: data.name,
      ...(data.description ? { description: data.description } : {}),
      ...(data.placeholder ? { placeholder: data.placeholder } : {})
    }
    try {
      setIsLoading(true);
      const res = await Datasets.updateDatasetField(data.dataset, data.id, requestData);
      onUpdateRow(res);
    } catch (e) {
      setChecked(!!data?.isPublic);
      toast.error(e?.response?.data?.message || "Something went wrong!");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <StyledSwitcher
      disabled={isLoading}
      checked={checked}
      onChange={handleChange}
      onClick={e => e.stopPropagation()}
    />
  )
};

export default SwitchPublic;
