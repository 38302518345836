import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";

import { User } from "services/User";

import ModalWrapper from "components/ModalWrapper";
import StyledSwitch from "components/StyledSwitch";

import Loader from "../Loader";
import StyledTextField from "../StyledTextField";

import "./styles.scss";

const NewUserModal = ({ open, onClose, getData }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [lastName, setLastName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    resetFields();
  }, [open]);

  const resetFields = () => {
    setLogin("");
    setPassword("");
    setLastName("");
    setFirstName("");
    setIsAdmin(false);
  }

  const handleCreateUser = async () => {
    try {
      setIsLoading(true);
      await User
        .createNewUser({
          "login": login,
          "firstName": firstName,
          "lastName": lastName,
          "password": password,
          "isAdmin": isAdmin
        });
      getData();
      onClose();
    } catch (e) {
      setError(e?.response?.data?.message)
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <ModalWrapper
      open={open}
      onClose={onClose}
      title="New user"
      contentClassName="new-user-modal"
    >
      {isLoading ? <Loader /> : null}
      <div className="form-wrapper">
        <div className="inputs-row">
          <StyledTextField
            label="Login"
            value={login}
            onChange={e => setLogin(e?.target?.value)}
            placeholder="User login"
          />
          <StyledTextField
            label="Password"
            value={password}
            onChange={e => setPassword(e?.target?.value)}
            placeholder="***********"
          />
        </div>
        <div className="inputs-row">
          <StyledTextField
            label="First name"
            placeholder="First name"
            value={firstName}
            onChange={e => setFirstName(e?.target?.value)}
          />
          <StyledTextField
            label="Last name"
            placeholder="Last name"
            value={lastName}
            onChange={e => setLastName(e?.target?.value)}
          />
        </div>
        {error ? <div className="error">{error}</div> : null}
        <div className="switcher-item">
          <div className="label">Make an admin</div>
          <div className="switcher-content">
            <StyledSwitch
              checked={isAdmin}
              onChange={e => setIsAdmin(e.target.checked)}
            />
          </div>
        </div>
      </div>
      <div className="controls">
        <Button className="cancel-btn" onClick={onClose}>Cancel</Button>
        <Button className="save-btn" onClick={handleCreateUser}>Create</Button>
      </div>
    </ModalWrapper>
  )
}

export default NewUserModal;