import React from "react";

const DatasetIcon = ({ color = "var(--main-text)" }) => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15 5V14C15 15.1047 12.3138 16 9 16C5.6862 16 3 15.1047 3 14V5" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M15 11C15 12.1047 12.3138 13 9 13C5.6862 13 3 12.1047 3 11M15 8C15 9.10467 12.3138 10 9 10C5.6862 10 3 9.10467 3 8" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M9 7C12.3137 7 15 5.88071 15 4.5C15 3.11929 12.3137 2 9 2C5.68629 2 3 3.11929 3 4.5C3 5.88071 5.68629 7 9 7Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default DatasetIcon;
