import React, { useState } from "react";
import { Button, Drawer } from "@mui/material";

import { useTableResize } from "hooks/tableResize";

import StyledTooltip from "components/StyledTooltip";

import SortArrow from "assets/iconComponents/SortArrow";
import CloseIcon from "assets/iconComponents/CloseIcon";

import Pagination from "./Pagination";
import "./styles.scss";

const TableRow = (
  {
    data,
    columns,
    onRowClick,
    openDrawer,
    onUpdateRow,
    isActive
  }
) => {
  return (
    <div
      className={`table-row ${onRowClick || openDrawer ? "clickable" : ""} ${isActive ? "active" : ""}`}
      onClick={() => {
        if (openDrawer) {
          openDrawer(data);
        }
        if (onRowClick) {
          onRowClick(data);
        }
      }}
    >
      {columns.map((cell, index) => (
        <div className={`table-column ${cell.className || ""}`} key={index} style={cell.style || {}}>
          {cell.getValue && !cell.getValueComponent && (
            <StyledTooltip title={cell.getValue({ data })} isOverflowContent>
              <div className="column-content">
                {cell.getValue({ data }) || "- - -"}
              </div>
            </StyledTooltip>
          )}
          {cell.getValueComponent && (
            <div className="column-content">
              {cell.getValueComponent({ data, onUpdateRow })}
            </div>
          )}
        </div>
      ))}
    </div>
  )
};

const CustomTable = (
  {
    columns,
    tableData,
    getData,
    onUpdateRow,
    isLoading,
    loaderRowsLength,
    sortByValue,
    sortDirValue,
    onSort,
    onRowClick,
    drawerTitle,
    drawerComponent
  }
) => {
  const { onMouseDown, tableColumns, dragData } = useTableResize(columns);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const loaderLength = !!tableData?.data?.length && tableData?.data?.length <= 15 ? tableData?.data?.length : loaderRowsLength || 10;

  const handleCloseDrawer = () => {
    setOpenDrawer(false);
  };

  return (
    <div className="custom-table-wrapper">
      <div className="table-content">
        <div className="table-row table-head">
          {tableColumns.map(cell => (
            <div
              className={`table-column ${cell.className || ""} ${cell?.sortKey ? "clickable" : ""}`}
              key={cell.label}
              ref={cell.ref}
              style={cell.style || {}}
              onClick={() => {
                if (cell?.sortKey) {
                  const dir = cell?.sortKey === sortByValue
                    ? sortDirValue?.toLowerCase() === "asc" ? "desc" : "asc"
                    : "asc";
                  onSort(cell?.sortKey, dir)
                }
              }}
            >
              <div className="column-content">
                {!cell?.sortKey ? (
                  cell.label
                ) : (
                  <div className="sort-label">
                    <div className="label">{cell?.label}</div>
                    <div className="sort-box">
                      <SortArrow
                        color={sortByValue === cell?.sortKey && sortDirValue?.toLowerCase() === "asc" ? "var(--main-text)" : ""}
                      />
                      <SortArrow
                        color={sortByValue === cell?.sortKey && sortDirValue?.toLowerCase() === "desc" ? "var(--main-text)" : ""}
                      />
                    </div>
                  </div>
                )}
                {!cell?.disableDrag && (
                  <div
                    className={`drag-bar ${dragData?.column?.label === cell?.label ? "active" : ""}`}
                    onMouseDown={e => onMouseDown(e, cell)}
                    onTouchStart={e => onMouseDown(e, cell)}
                  />
                )}
              </div>
            </div>
          ))}
        </div>
        <div className="table-body">
          {isLoading ? new Array(loaderLength).fill(0).map((_, index) => (
            <div className="table-row" key={index}>
              {tableColumns.map(cell => (
                <div className="table-column" key={cell.label} style={cell.style || {}}>
                  <div className="column-placeholder" />
                </div>
              ))}
            </div>
          )) : tableData?.data?.map((row, index) => (
            <TableRow
              key={index}
              data={row}
              columns={tableColumns}
              onRowClick={onRowClick}
              onUpdateRow={onUpdateRow}
              isActive={openDrawer && JSON.stringify(selectedRow) === JSON.stringify(row)}
              openDrawer={!!drawerComponent ? row => {
                setSelectedRow(row);
                setOpenDrawer(true);
              } : null}
            />
          ))}
          {!!drawerComponent && (
            <Drawer
              anchor="right"
              className="table-drawer"
              open={openDrawer}
              onClose={handleCloseDrawer}
            >
              <div className="table-drawer-head">
                <div className="drawer-title">{drawerTitle && drawerTitle(selectedRow)}</div>
                <Button className="close-drawer-btn" onClick={handleCloseDrawer}>
                  <CloseIcon />
                </Button>
              </div>
              {!!selectedRow && drawerComponent(selectedRow, handleCloseDrawer)}
            </Drawer>
          )}
        </div>
      </div>
      {getData && (
        <Pagination
          isLoading={isLoading}
          page={tableData.page}
          items={tableData?.data?.length || 0}
          totalItems={tableData?.totalItems}
          totalPages={tableData?.totalPages}
          onChange={page => getData(page, tableData?.limit)}
          limit={tableData?.limit}
          onLimitChange={newLimit => getData(1, newLimit)}
        />
      )}
    </div>
  )
};

export default CustomTable;
