import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";

import { User } from "services/User";

import StyledSwitch from "components/StyledSwitch";
import StyledTextField from "components/StyledTextField";
import StatusBadge, { statusBadgeStates } from "components/CustomTable/columnsComponents/StatusBadge";

import "./styles.scss";

const ChangeAccessModal = ({ user, getData = () => { } }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [initialLimits, setInitialLimits] = useState({});
  const [limitsSettings, setLimitsSettings] = useState({
    periodRows: 0,
    ttlRequests: 0,
    periodRequests: 0,
    ttl: 0,
    storageBytesLimit: 0,
  })

  const [accountSettings, setAccountSettings] = useState({
    searchBlocked: false,
    accountBlocked: !!user?.isBlocked,
    isAdmin: !!user?.isAdmin
  })
  const [limitsStats, setLimitsStats] = useState({
    used: 0,
    max: 0
  })

  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 3,
  });

  useEffect(() => {
    getUserData();
  }, []);

  const getUserData = async () => {
    try {
      setIsLoading(true);
      const res = await User.getUsageLimit(user.id);
      setInitialLimits(res);
      setLimitsSettings(res);
      setLimitsStats({ used: res.usage.rows, max: res.periodRows });
      setAccountSettings({ ...accountSettings, searchBlocked: res.blocked });
    } catch (e) {
      console.log(e)
    } finally {
      setIsLoading(false);
    }
  }

  const updateUser = async () => {
    try {
      setIsLoading(true);
      await User.putUsageLimit(user.id, {
        ttl: Number(limitsSettings.ttl),
        ttlRequests: Number(limitsSettings.ttlRequests),
        blocked: accountSettings.searchBlocked,
        periodRequests: Number(limitsSettings.periodRequests),
        periodRows: Number(limitsSettings.periodRows),
        storageBytesLimit: Number(limitsSettings.storageBytesLimit)
      });
      await User.putUserData(user.id, {
        login: user.login,
        firstName: user.firstName,
        lastName: user.lastName,
        isBlocked: accountSettings.accountBlocked,
        isAdmin: accountSettings.isAdmin
      });
      getData();
    } catch (e) {
      console.log(e)
    } finally {
      setIsLoading(false);
    }
  }

  const changeLimitsSettings = data => setLimitsSettings(state => ({ ...state, ...data }));
  const changeAccountSettings = data => setAccountSettings(state => ({ ...state, ...data }));

  return (
    <div className={`change-access-modal ${isLoading ? "loading" : ""}`}>

      <div className="headline">
        Access

        <div className="limits-box">
          <span className="used">{formatter.format(limitsStats.used)}</span>
          <span>/ {formatter.format(limitsStats.max)}</span>
        </div>
      </div>

      <StyledTextField
        type="number"
        label="Credits per month"
        value={limitsSettings.periodRows}
        onChange={e => changeLimitsSettings({ periodRows: e?.target?.value })}
      />
      <StyledTextField
        type="number"
        label="Requests per month"
        value={limitsSettings.periodRequests}
        onChange={e => changeLimitsSettings({ periodRequests: e?.target?.value })}
      />
      <StyledTextField
        type="number"
        className="half"
        label="Records"
        value={limitsSettings.ttlRequests}
        onChange={e => changeLimitsSettings({ ttlRequests: e?.target?.value })}
      />
      <StyledTextField
        type="number"
        className="half"
        label="RL (Seconds)"
        value={limitsSettings.ttl}
        onChange={e => changeLimitsSettings({ ttl: e?.target?.value })}
      />
      <StyledTextField
        type="number"
        label="Storage Bytes Limit"
        value={limitsSettings.storageBytesLimit}
        onChange={e => changeLimitsSettings({ storageBytesLimit: e?.target?.value })}
      />
      <div className="switcher-item">
        <div className="label">Search state</div>
        <div className="switcher-content">
          <StyledSwitch
            disabled={isLoading}
            checked={!accountSettings.searchBlocked}
            onChange={e => changeAccountSettings({ searchBlocked: !e.target.checked })}
          />
          <StatusBadge
            label={accountSettings.searchBlocked ? "Blocked" : "Active"}
            state={accountSettings.searchBlocked ? statusBadgeStates.disabled : statusBadgeStates.active}
          />
        </div>
      </div>
      <div className="switcher-item">
        <div className="label">Account state</div>
        <div className="switcher-content">
          <StyledSwitch
            disabled={isLoading}
            checked={!accountSettings.accountBlocked}
            onChange={e => changeAccountSettings({ accountBlocked: !e.target.checked })}
          />
          <StatusBadge
            label={accountSettings.accountBlocked ? "Blocked" : "Active"}
            state={accountSettings.accountBlocked ? statusBadgeStates.disabled : statusBadgeStates.active}
          />
        </div>
      </div>
      <div className="switcher-item">
        <div className="label">Make an admin</div>
        <div className="switcher-content">
          <StyledSwitch
            disabled={isLoading}
            checked={accountSettings.isAdmin}
            onChange={e => changeAccountSettings({ isAdmin: e.target.checked })}
          />
          <StatusBadge
            label={accountSettings.isAdmin ? "Active" : "Disabled"}
            state={accountSettings.isAdmin ? statusBadgeStates.active : statusBadgeStates.disabled}
          />
        </div>
      </div>
      <div className="controls">
        <Button onClick={updateUser}>Update</Button>
      </div>
    </div>
  )
}

export default ChangeAccessModal;