import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { User } from "services/User";
import { Datasets } from "services/Datasets";

import StyledSwitch from "components/StyledSwitch";
import StatusBadge, { statusBadgeStates } from "components/CustomTable/columnsComponents/StatusBadge";

import "./styles.scss";

const DatasetItem = ({ userId, data, userDatasets }) => {
  const [isActive, setIsActive] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsActive(userDatasets.some(it => it.id === data.id));
  }, [userDatasets]);

  const requestAccess = async () => {
    try {
      setIsLoading(true);
      await User.requestDatasetAccess(data.id, { userId });
      setIsActive(true);
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  }

  const removeAccess = async () => {
    try {
      setIsLoading(true);
      await User.removeDatasetAccess(data.id, { userId });
      setIsActive(false);
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <div className="dataset-item">
      <div className="label">{data.name}</div>
      <div className="switcher-content">
        <StyledSwitch
          disabled={isLoading}
          checked={isActive}
          onChange={e => e.target.checked ? requestAccess() : removeAccess()}
        />
        <StatusBadge
          label={isActive ? "Active" : "Disabled"}
          state={isActive ? statusBadgeStates.active : statusBadgeStates.disabled}
        />
      </div>
    </div>
  )
}

const UserDatasetsModal = ({ user }) => {
  const datasets = useSelector(store => store.datasets.data);
  const [isLoading, setIsLoading] = useState(true);
  const [userDatasets, setUserDatasets] = useState({
    data: [],
    page: 1,
    limit: 20,
    totalItems: 0,
    totalPages: 0
  });

  useEffect(() => {
    getUserDatasets();
  }, []);

  const getUserDatasets = async () => {
    try {
      setIsLoading(true);
      const res = await Datasets.getDatasets({ userId: user.id });
      setUserDatasets(res)
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <div className={`user-datasets-modal ${isLoading || datasets.isLoading ? "loading" : ""}`}>

      <div className="headline">Datasets</div>

      <div className="datasets-list">
        <div className="scroll-wrapper">
          {datasets?.map(dataset => <DatasetItem userId={user.id} data={dataset} userDatasets={userDatasets.data} key={dataset.id} />)}
        </div>
      </div>
    </div>
  )
}

export default UserDatasetsModal;