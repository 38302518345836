import React, { useState } from "react";
import { Button } from "@mui/material";

import { User } from "services/User";

import StyledTextField from "components/StyledTextField";

import "./styles.scss";
import {toast} from "react-toastify";

const ChangeGeneralModal = ({ user, getData=()=>{} }) => {
  const [showGeneralConfirmPopover, setShowGeneralConfirmPopover] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [login, setLogin] = useState(user?.login);
  const [password, setPassword] = useState("");
  const [lastName, setLastName] = useState(user.lastName);
  const [firstName, setFirstName] = useState(user.firstName);

  const isFormDifferent = () => {
    return (
      login !== user.login ||
      password !== "" ||
      lastName !== user.lastName ||
      firstName !== user.firstName
    )
  }

  const updateUser = async () => {
    try {
      setIsLoading(true);
      const data = {
        "login": login,
        "firstName": firstName,
        "lastName": lastName,
        "isBlocked": user.isBlocked,
        "isAdmin": user.isAdmin,
      };
      if (password.trim()) {
        data.password = password;
      }
      await User.putUserData(user.id, data);
      setPassword("");
      toast.success("Changes were saved successfully");
      getData();
    } catch (e) {
      console.log(e)
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={`change-general-modal ${isLoading ? "loading" : ""}`}>

      <div className="headline">General</div>

      <StyledTextField
        label="Login"
        value={login}
        onChange={e => setLogin(e?.target?.value)}
      />
      <StyledTextField
        type="password"
        label="Password"
        value={password}
        onChange={e => setPassword(e?.target?.value)}
        placeholder="***********"
      />
      <StyledTextField
        label="First name"
        placeholder="First name"
        value={firstName}
        onChange={e => setFirstName(e?.target?.value)}
      />
      <StyledTextField
        label="Last name"
        placeholder="Last name"
        value={lastName}
        onChange={e => setLastName(e?.target?.value)}
      />
      <div className="controls">
        <Button
          className="save-btn"
          disabled={!isFormDifferent()}
          onClick={updateUser}
        >
          Update
        </Button>
      </div>
    </div>
  )
}

export default ChangeGeneralModal;