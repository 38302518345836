import { createSlice } from '@reduxjs/toolkit'

export const datasetsSlice = createSlice({
  name: 'datasets',
  initialState: {
    data: [],
    isLoading: true
  },
  reducers: {
    setDatasetsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setDatasetsData: (state, action) => {
      state.data = action.payload;
    },
  },
})

// Action creators are generated for each case reducer function
export const { setDatasetsData, setDatasetsLoading } = datasetsSlice.actions

export default datasetsSlice.reducer