import React, { useEffect, useState } from "react";
import { Button, Popover } from "@mui/material";

import { User } from "services/User";
import { userColumns } from "utils/tables";

import CustomTable from "components/CustomTable";
import NewUserModal from "components/NewUserModal";
import UserDetails from "components/CustomTable/drawerComponents/UserDetails";

import "./styles.scss";

const UsersList = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [usersData, setUsersData] = useState({
    data: [],
    totalItems: 0,
    page: 1,
    limit: 20,
    totalPages: 0
  });
  const [showNewUserModal, setShowNewUserModal] = useState(false);

  useEffect(() => {
    getUsersList();
  }, []);

  const getUsersList = async (page, limit) => {
    try {
      setIsLoading(true);
      const res = await User.getUsers({
        limit: limit || usersData.limit,
        page: page || 1
      })
      setUsersData(res);
    } catch (e) {
      console.log(e);
      setUsersData({
        data: [],
        totalItems: 0,
        page: 1,
        limit: 20,
        totalPages: 0
      })
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="home-page">
      <div className="users-list">
        <div className="list-header">
          <div className="left-box">
            <div className="list-title">Users list</div>
            <div className="counter">{usersData.totalItems} Users</div>
          </div>

          <div className="right-box">
            <Button onClick={() => setShowNewUserModal(true)}>Add user</Button>
            <NewUserModal
              open={showNewUserModal}
              getData={getUsersList}
              onClose={() => setShowNewUserModal(false)}
            />
          </div>
        </div>

        <CustomTable
          isLoading={isLoading}
          columns={userColumns}
          tableData={usersData}
          getData={getUsersList}
          drawerTitle={() => "User overview"}
          drawerComponent={(data, onClose) => <UserDetails data={data} onClose={onClose} getData={getUsersList}/>}
        />
      </div>
    </div>
  )
}

export default UsersList;