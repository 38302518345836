import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { Button, Drawer } from "@mui/material";

import { DATASETS, LOGIN, USERS } from "pathnameVariables";
import useWindowSize from "hooks/useWindowResize";

import Logo from "../Logo";
import GeneralLimitsModal from "../GeneralLimitsModal";

import UserIcon from "assets/iconComponents/UserIcon";
import LimitIcon from "assets/iconComponents/LimitIcon";
import LogoutIcon from "assets/iconComponents/LogoutIcon";
import BurgerIcon from "assets/iconComponents/BurgerIcon";
import DatasetIcon from "assets/iconComponents/DatasetIcon";

import "./styles.scss";

const Sidebar = () => {
  const [width] = useWindowSize();
  const navigate = useNavigate();
  const userData = useSelector(store => store.user.userData)
  const { pathname } = useLocation();
  const [showLimitsModal, setShowLimitsModal] = useState(false);
  const [isSidebarOpened, setIsSidebarOpened] = useState(true);
  const isMobile = width <= 768;

  useEffect(() => {
    setIsSidebarOpened(width > 768);
  }, [width])

  const handleLogout = () => {
    localStorage.removeItem("access_token");
    navigate(LOGIN);
  }

  const routes = [
    {
      label: "Users",
      pathname: USERS,
      isActive: pathname === USERS,
      icon: UserIcon
    },
    {
      label: "Datasets",
      pathname: DATASETS,
      isActive: pathname === DATASETS,
      icon: DatasetIcon
    },
  ]

  return (
    <div className="sidebar-container">
      <div className="mobile-controls">
        <Button onClick={() => setIsSidebarOpened(true)}>
          <BurgerIcon />
        </Button>
        <Logo />
      </div>
      <Drawer
        variant={isMobile ? undefined : "permanent"}
        anchor="left"
        open={isSidebarOpened}
        onClose={() => setIsSidebarOpened(false)}
        className="sidebar-content-wrapper"
      >
        <div className="top-part">
          <Logo />
          <div className="routes-wrapper">
            <Button
              className="limit-btn"
              onClick={e => {
                setShowLimitsModal(true);
                if (isMobile) {
                  setIsSidebarOpened(false);
                }

              }}
            >
              <LimitIcon color="var(--primary-color)" />
              Limit Settings
            </Button>

            {routes.map(route => (
              <Link
                to={route.pathname}
                className={`sidebar-route-item ${route.isActive ? "active" : ""}`}
                key={route.pathname}
                onClick={() => setIsSidebarOpened(!isMobile)}
              >
                <route.icon color={route.isActive ? "var(--primary-color)" : "var(--main-text)"} />
                {route.label}
              </Link>
            ))}
          </div>
        </div>

        <div className="profile-box">
          <div className="default-avatar">{!!userData.firstName ? userData.firstName[0].toUpperCase() : ""}</div>
          <div className="user-name">{userData.firstName} {userData.lastName}</div>
          <Button className="logout-btn" onClick={handleLogout}>
            <LogoutIcon />
          </Button>
        </div>
      </Drawer>

      <GeneralLimitsModal
        open={showLimitsModal}
        onClose={() => setShowLimitsModal(false)}
      />
    </div>
  )
}

export default Sidebar;