import React, { useState, useEffect, useCallback } from "react";
import { Button } from "@mui/material";
import { useSelector } from "react-redux";
import { debounce } from "lodash";

import { Datasets } from "services/Datasets";
import { datasetFieldColumns } from "utils/tables";

import EditDatasetModal from "components/EditDatasetModal";
import CreateDatasetGroupModal from "components/CreateDatasetGroupModal";
import StyledTabs from "components/StyledTabs";
import SvgIcon from "components/SvgIcon";
import CustomTable from "components/CustomTable";
import DatasetFieldSettings from "components/CustomTable/drawerComponents/DatasetFieldSettings";
import StyledTextField from "components/StyledTextField";

import "./styles.scss";

const Dataset = () => {
  const datasets = useSelector(store => store.datasets.data);
  const [isLoading, setIsLoading] = useState(true);
  const [createGroupOpen, setCreateGroupOpen] = useState(false);
  const [editDatasetOpen, setEditDatasetOpen] = useState(false);
  const [datasetsList, setDatasetsList] = useState([]);
  const [groupsList, setGroupsList] = useState([]);
  const [currentDataset, setCurrentDataset] = useState(null);
  const [datasetTableData, setDatasetTableData] = useState({
    data: [],
    totalItems: 0,
    page: 1,
    limit: 20,
    totalPages: 0
  });
  const [sortBy, setSortBy] = useState("order");
  const [sortDirection, setSortDirection] = useState("asc");
  const [searchValue, setSearchValue] = useState("");

  const changeTableData = ({ page, limit, fields, sortByValue, sortDirValue, search }) => {
    const pageLimit = limit || datasetTableData.limit;
    const sliceFrom = (page * pageLimit) - pageLimit;
    const sortKey = sortByValue || sortBy;
    const sortDir = sortDirValue || sortDirection;
    const data = (fields || currentDataset?.fields || [])
      .filter(val => val.key.toLowerCase().includes((search !== undefined ? search : searchValue).toLowerCase()))
      .sort((a, b) => {
      if (typeof a[sortKey] === "string") {
        return sortDir === "asc" ? a[sortKey].localeCompare(b[sortKey]) : b[sortKey].localeCompare(a[sortKey]);
      }
      return sortDir === "asc" ? a[sortKey] - b[sortKey] : b[sortKey] - a[sortKey];
    });
    setDatasetTableData({
      data: data?.slice(sliceFrom, sliceFrom + pageLimit),
      totalItems: data?.length || 0,
      page: page,
      limit: pageLimit,
      totalPages: Math.ceil(data?.length / pageLimit || 0)
    });
  };

  const debouncedChangeTableData = useCallback(debounce(changeTableData, 500), [currentDataset]);

  const getData = async (page) => {
    const [fullInfo, groups] = await Promise.all([
      Promise.all(datasets.map(val => Datasets.getDataset(val.id, { includes: "fields,field_groups" }))),
      Datasets.getGroups({ limit: 100 })
    ])
    const defaultDataset = currentDataset ? fullInfo?.find(val => val.id === currentDataset?.id) : fullInfo?.sort((a, b) => a.id > b.id ? 1 : -1)?.[0];
    setCurrentDataset(defaultDataset);
    changeTableData({
      page: page || 1,
      fields: defaultDataset?.fields
    });
    setGroupsList(groups?.data || []);
    setDatasetsList(fullInfo || []);
    setIsLoading(false);
  };

  useEffect(() => {
    if(datasets.length){
      getData();
    }
  }, [datasets.length]);

  const changeTab = (tabId) => {
    const dataset = datasetsList?.find(val => val.id === tabId);
    setCurrentDataset(dataset);
    setSearchValue("");
    changeTableData({ page: 1, fields: dataset?.fields, search: "" });
  };

  const updateCurrentDataset = (newDataset) => {
    setDatasetsList(prevState => {
      const index = prevState.findIndex(obj => obj.id === newDataset.id);
      if (index !== -1) {
        const updatedDatasetsList = [...prevState];
        updatedDatasetsList[index] = newDataset;
        return updatedDatasetsList;
      }
      return prevState;
    })
  };

  const updateFieldItem = newItem => {
    setCurrentDataset(prevDataset => {
      const index = prevDataset.fields.findIndex(obj => obj.id === newItem.id);
      if (index !== -1) {
        const updatedFields = [...prevDataset.fields];
        updatedFields[index] = newItem;
        const updatedDataset = { ...prevDataset, fields: updatedFields };
        changeTableData({
          page: datasetTableData.page,
          fields: updatedFields
        });
        updateCurrentDataset(updatedDataset);
        return updatedDataset;
      }
      return prevDataset;
    });
  };

  const handleSort = (key, dir) => {
    setSortBy(key);
    setSortDirection(dir);
    changeTableData({
      page: 1,
      sortByValue: key,
      sortDirValue: dir
    });
  };

  return (
    <div className="dataset-page">
      <div className="page-content">
        <div className="page-title">
          Datasets
          <Button className="create-btn" disabled={isLoading} onClick={() => setCreateGroupOpen(true)}>
            Manage Groups
          </Button>
          <CreateDatasetGroupModal
            state={createGroupOpen}
            setState={setCreateGroupOpen}
            groupsList={groupsList}
            setGroups={setGroupsList}
          />
        </div>
        <StyledTabs
          isLoading={isLoading}
          value={currentDataset?.id}
          onChange={changeTab}
          tabs={datasetsList?.sort((a, b) => a.id > b.id ? 1 : -1)?.map(val => ({
            value: val.id,
            label: val.name,
            getIcon: color => <SvgIcon svgString={val.icon} color={color} />
          }))}
        />
        <div className="filter-box">
          <StyledTextField
            disabled={isLoading}
            placeholder="Search By Key"
            value={searchValue}
            onChange={e => {
              setSearchValue(e.target.value);
              debouncedChangeTableData({
                search: e.target.value,
                page: 1,
                limit: datasetTableData.limit,
                sortByValue: sortBy,
                sortDirValue: sortDirection
              })
            }}
          />
          <Button
            className="edit-btn"
            disabled={isLoading}
            onClick={() => setEditDatasetOpen(true)}
          >
            Edit Dataset
          </Button>
          <EditDatasetModal
            state={editDatasetOpen}
            setState={setEditDatasetOpen}
            setDatasetData={newDataset => {
              const dataset = { ...newDataset, fields: currentDataset?.fields };
              setCurrentDataset(dataset);
              updateCurrentDataset(dataset);
            }}
            datasetData={currentDataset}
          />
        </div>
        <CustomTable
          columns={datasetFieldColumns}
          tableData={datasetTableData}
          isLoading={isLoading}
          getData={(page, limit) => changeTableData({ page, limit })}
          onUpdateRow={updateFieldItem}
          sortByValue={sortBy}
          sortDirValue={sortDirection}
          onSort={handleSort}
          drawerTitle={row => row?.key}
          drawerComponent={(row) => (
            <DatasetFieldSettings
              data={row}
              groupsList={groupsList}
              groupFieldsMapping={currentDataset?.groupFieldsMapping}
              updateFieldItem={updateFieldItem}
              getData={() => getData(datasetTableData.page)}
            />
          )}
        />
      </div>
    </div>
  )
};

export default Dataset;
