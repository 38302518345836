
export const fieldFormatOptions = [
  {label: "Text", value: "plain"},
  {label: "Phone", value: "phone"},
  {label: "Email", value: "email"},
  {label: "IP", value: "ip"}
];

export const paginationLimitOptions = [
  { label: 20, value: 20 },
  { label: 40, value: 40 },
  { label: 80, value: 80 },
  { label: 100, value: 100 }
];
