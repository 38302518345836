import { ApiService } from "./ApiService";

export class Datasets {
  static async getDatasets(params) {
    return ApiService._requestAsync({
      url: "/admin/datasets",
      method: "GET",
      params
    });
  }
  static async getDataset(id, params) {
    return ApiService._requestAsync({
      url: `/admin/datasets/${id}`,
      method: "GET",
      params
    });
  }
  static async updateDataset(id, data) {
    return ApiService._requestAsync({
      url: `/admin/datasets/${id}`,
      method: "PUT",
      data
    });
  }
  static async updateDatasetField(datasetId, fieldId, data) {
    return ApiService._requestAsync({
      url: `/admin/datasets/${datasetId}/fields/${fieldId}`,
      method: "PUT",
      data
    });
  }
  static async getGroups(params) {
    return ApiService._requestAsync({
      url: `/admin/dataset-groups`,
      method: "GET",
      params
    });
  }
  static async createGroup(data) {
    return ApiService._requestAsync({
      url: `/admin/dataset-groups`,
      method: "POST",
      data
    });
  }
  static async deleteGroup(id) {
    return ApiService._requestAsync({
      url: `/admin/dataset-groups/${id}`,
      method: "DELETE"
    });
  }
  static async addFieldToGroup(data) {
    return ApiService._requestAsync({
      url: `/admin/dataset-groups/field-mappings`,
      method: "POST",
      data
    });
  }
  static async removeFieldFromGroup(data) {
    return ApiService._requestAsync({
      url: `/admin/dataset-groups/field-mappings`,
      method: "DELETE",
      data
    });
  }
}
