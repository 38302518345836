import { ApiService } from "./ApiService";

export class User {
  static async login(data) {
    return ApiService._requestAsync({
      url: "/auth/login",
      method: "POST",
      data
    });
  }
  static async getCurrentUser() {
    return ApiService._requestAsync({
      url: `/me`,
      method: "GET"
    });
  }
  static async getUsers({ limit = 10, page = 1 }) {
    return ApiService._requestAsync({
      url: `/admin/users?limit=${limit}&page=${page}`,
      method: "GET"
    });
  }

  static async createNewUser(data) {
    return ApiService._requestAsync({
      url: `/admin/users`,
      method: "POST",
      data
    });
  }
  static async putUserData(userId, data) {
    return ApiService._requestAsync({
      url: `/admin/users/${userId}`,
      method: "PUT",
      data
    });
  }
  static async getUsageLimit(userId) {
    return ApiService._requestAsync({
      url: `/admin/users/${userId}/usage-limit`,
      method: "GET"
    });
  }
  static async putUsageLimit(userId, data) {
    return ApiService._requestAsync({
      url: `/admin/users/${userId}/usage-limit`,
      method: "PUT",
      data
    });
  }
  static async getSessionsList(userId) {
    return ApiService._requestAsync({
      url: `/admin/users/${userId}/sessions`,
      method: "GET",
    });
  }
  static async deleteSession(userId, sessionId) {
    return ApiService._requestAsync({
      url: `/admin/users/${userId}/sessions/${sessionId}`,
      method: "DELETE",
    });
  }
  static async deleteUser(userId) {
    return ApiService._requestAsync({
      url: `/admin/users/${userId}`,
      method: "DELETE",
    });
  }
  static async requestDatasetAccess(id, data) {
    return ApiService._requestAsync({
      url: `/admin/dataset-access/${id}/access`,
      method: "POST",
      data
    });
  }
  static async removeDatasetAccess(id, data) {
    return ApiService._requestAsync({
      url: `/admin/dataset-access/${id}/access`,
      method: "DELETE",
      data
    });
  }
}
