import React from "react";
import { Switch } from "@mui/material";
import "./styles.scss";

const StyledSwitch = ({ className, disabled, ...restProps }) => {
  return (
    <div className={`styled-switch-item ${className || ""} ${disabled ? "disabled" : ""}`}>
      <Switch
        disableRipple
        {...restProps}
      />
    </div>
  )
}

export default StyledSwitch;