import React from "react";
import Switch from "@mui/material/Switch";
import "./styles.scss";

const StyledSwitcher = ({ checked, onChange, onClick, disabled, className }) => {
  return (
    <Switch
      className={`styled-switcher ${className || ""}`}
      checked={checked}
      onChange={onChange}
      disabled={disabled}
      onClick={onClick}
    />
  )
};

export default StyledSwitcher;
