import React from "react";
import "./styles.scss";

export const statusBadgeStates = {
  disabled: { color: "var(--grey-color)", background: "var(--grey-bg)" },
  active: { color: "var(--green-color)", background: "var(--green-bg)" },
  process: { color: "var(--orange-color)", background: "var(--orange-bg)" },
  error: { color: "var(--error-color)", background: "var(--error-bg)" }
};

const StatusBadge = ({ label, state }) => {
  return (
    <div
      className="status-badge"
      style={{
        "--status-color": state?.color || statusBadgeStates.disabled.color,
        "--status-bg": state?.background || statusBadgeStates.disabled.background
      }}
    >
      {label}
    </div>
  )
};

export default StatusBadge;
