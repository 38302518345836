import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { Button } from "@mui/material";

import { Datasets } from "services/Datasets";
import { fieldFormatOptions } from "utils/options";

import StyledTextField from "components/StyledTextField";
import StyledSelect from "components/StyledSelect";
import StyledSwitch from "components/StyledSwitch";
import SvgIcon from "components/SvgIcon";

import "./styles.scss";

const DatasetFieldSettings = ({ data, groupsList, groupFieldsMapping, updateFieldItem, getData }) => {
  const groupOptions = groupsList?.map(val => ({
    value: val.id,
    label: val.name,
    iconComponent: <SvgIcon svgString={val.icon} />
  })) || [];
  const currentGroup = groupFieldsMapping?.filter(val => val.id > 0)?.find(group => group.fieldIds?.includes(data.id))?.id;

  const [group, setGroup] = useState(currentGroup || "empty");
  const [isLoading, setIsLoading] = useState(false);
  const [values, setValues] = useState(data);
  const [errors, setErrors] = useState({});

  const handleSave = async () => {
    if (values.isMultiValueSearchable && (+values.multiValueSearchableMaxSize < 1 || +values.multiValueSearchableMaxSize > 100)) {
      return setErrors({ ...errors, multiValueSearchableMaxSize: "Max size of a multi-search value must be between 1 and 100" });
    }
    if (!values.name) {
      return setErrors({ ...errors, name: "Column Label is required." });
    }
    if (!values.searchInputLabel) {
      return setErrors({ ...errors, searchInputLabel: "Field Label is required." });
    }
    const requestData = {
      format: values.format,
      isPublic: values.isPublic,
      isMultiValueSearchable: values.isMultiValueSearchable,
      name: values.name,
      searchInputLabel: values.searchInputLabel,
      multiValueSearchableMaxSize: +(values.multiValueSearchableMaxSize || data.multiValueSearchableMaxSize),
      ...(values.description ? { description: values.description } : {}),
      ...(values.placeholder ? { placeholder: values.placeholder } : {})
    }
    try {
      setIsLoading(true);
      const needRemoveGroup = (group === "empty" && !!currentGroup) || group !== "empty" && !!currentGroup && group !== currentGroup;
      const needUpdateGroup = group !== "empty" && group !== currentGroup;

      const res = await Datasets.updateDatasetField(data.dataset, data.id, requestData);

      if (needRemoveGroup) {
        await Datasets.removeFieldFromGroup({
          datasetFieldId: data.id,
          datasetId: data.dataset,
          datasetFieldGroupId: currentGroup
        });
      }
      if (needUpdateGroup) {
        await Datasets.addFieldToGroup({
          datasetFieldId: data.id,
          datasetId: data.dataset,
          datasetFieldGroupId: group
        });
      }
      if (needRemoveGroup || needUpdateGroup) {
        await getData();
      } else {
        updateFieldItem(res);
      }
      setValues(res);
      toast.success("Field successfully updated");
    } catch (e) {
      toast.error(e?.response?.data?.message || "Something went wrong!");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={`dataset-field-settings ${isLoading ? "loading" : ""}`}>
      <div className="switcher-item">
        <div className="label">Public</div>
        <StyledSwitch
          checked={values?.isPublic}
          onChange={e => setValues({ ...values, isPublic: e.target.checked })}
        />
      </div>
      <div className="switcher-item">
        <div className="label">Multi Searchable</div>
        <StyledSwitch
          checked={values?.isMultiValueSearchable}
          onChange={e => setValues({ ...values, isMultiValueSearchable: e.target.checked })}
        />
      </div>
      {values?.isMultiValueSearchable && (
        <StyledTextField
          label="Max size of a multi-search value (1 - 100)"
          type="number"
          placeholder="50"
          required
          disabled={isLoading}
          value={values.multiValueSearchableMaxSize || ""}
          onChange={(e) => {
            setValues({ ...values, multiValueSearchableMaxSize: e.target.value });
            setErrors({ ...errors, multiValueSearchableMaxSize: "" });
          }}
          errorText={errors?.multiValueSearchableMaxSize}
        />
      )}
      <StyledTextField
        label="Column Label"
        required
        disabled={isLoading}
        value={values.name || ""}
        onChange={(e) => {
          setValues({ ...values, name: e.target.value });
          setErrors({ ...errors, name: "" });
        }}
        errorText={errors?.name}
      />
      {data?.isSearchable && (
        <StyledTextField
          label="Field Label"
          required
          disabled={isLoading}
          value={values.searchInputLabel || ""}
          onChange={(e) => {
            setValues({ ...values, searchInputLabel: e.target.value });
            setErrors({ ...errors, searchInputLabel: "" });
          }}
          errorText={errors?.searchInputLabel}
        />
      )}
      <StyledTextField
        label="Description"
        placeholder="Describe this field..."
        disabled={isLoading}
        value={values.description || ""}
        onChange={(e) => setValues({ ...values, description: e.target.value })}
        multiline
        rows={2}
      />
      {data?.isSearchable && (
        <>
          <StyledTextField
            label="Placeholder"
            disabled={isLoading}
            value={values.placeholder || ""}
            onChange={(e) => setValues({ ...values, placeholder: e.target.value })}
          />
          <StyledSelect
            label="Format"
            disabled={isLoading}
            value={values.format || ""}
            onChange={val => setValues({ ...values, format: val })}
            options={fieldFormatOptions}
          />
          <StyledSelect
            label="Group"
            disabled={isLoading}
            value={group || ""}
            onChange={val => setGroup(val)}
            options={[
              { label: "Not Selected", value: "empty" },
              ...groupOptions
            ]}
          />
        </>
      )}
      <div className="btn-box">
        <Button className="save-btn" onClick={handleSave} disabled={isLoading}>
          Save changes
        </Button>
      </div>
    </div>
  )
};

export default DatasetFieldSettings;
