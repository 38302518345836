import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { useDispatch } from "react-redux";

import { User } from "services/User";
import { Datasets } from "services/Datasets";
import { setUserData } from "../../redux/reducers/user";
import { setDatasetsData, setDatasetsLoading } from "../../redux/reducers/datasets";

import Sidebar from "components/Sidebar";

import "./styles.scss";

const AppWrapper = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    getUserData();
    getDatasetsList();
  }, []);

  const getUserData = async () => {
    try {
      const res = await User.getCurrentUser();
      dispatch(setUserData(res));
    } catch (e) {
      console.log(e)
    }
  }

  const getDatasetsList = async () => {
    try {
      dispatch(setDatasetsLoading(true));
      const res = await Datasets.getDatasets();
      dispatch(setDatasetsData(res?.data));
    } catch (e) {
      console.log(e)
      dispatch(setDatasetsLoading(false));
    }
  }

  return (
    <div className="app-wrapper">
      <Sidebar />
      <div className="page-wrapper">
        <Outlet />
      </div>
    </div>
  )
};

export default AppWrapper;
