import React from "react";
import StatusBadge, { statusBadgeStates } from "components/CustomTable/columnsComponents/StatusBadge";
import SwitchPublic from "components/CustomTable/columnsComponents/SwitchPublic";
import { fieldFormatOptions } from "utils/options";

export const userColumns = [
  {
    label: "ID",
    getValue: ({ data }) => data?.user?.id,
    style: { minWidth: "200px" }
  },
  {
    label: "Name",
    getValue: ({ data }) => `${data?.user?.firstName} ${data?.user?.lastName}`,
    style: { minWidth: "200px" }
  },
  {
    label: "Login",
    getValue: ({ data }) => data?.user?.login,
    style: { minWidth: "200px" }
  },
  {
    label: "Status",
    getValueComponent: ({ data }) => (
      <StatusBadge
        label={data?.limits?.blocked ? "Blocked" : "Active"}
        state={data?.limits?.blocked ? statusBadgeStates.disabled : statusBadgeStates.active}
      />
    ),
    style: { minWidth: "65px" }
  }
];

export const datasetFieldColumns = [
  {
    label: "Public",
    getValueComponent: ({ data, onUpdateRow }) => <SwitchPublic data={data} onUpdateRow={onUpdateRow} />,
    disableDrag: true,
    sortKey: "isPublic",
    style: { minWidth: "65px", maxWidth: "65px" }
  },
  {
    label: "Key",
    getValue: ({ data }) => data?.key,
    sortKey: "key",
    style: { minWidth: "200px" }
  },
  {
    label: "Column Label",
    getValue: ({ data }) => data?.name,
    sortKey: "name",
    style: { minWidth: "200px" }
  },
  {
    label: "Order",
    getValue: ({ data }) => `${data?.order}`,
    sortKey: "order",
    style: { minWidth: "60px" }
  },
  {
    label: "Format",
    getValue: ({ data }) => fieldFormatOptions.find(val => val.value === data?.format)?.label || data?.format,
    sortKey: "format",
    style: { minWidth: "100px" }
  },
  {
    label: "Placeholder",
    getValue: ({ data }) => data?.placeholder,
    style: { minWidth: "100px" }
  },
  {
    label: "Type",
    getValueComponent: ({ data }) => (
      <StatusBadge
        label={data?.isSearchable ? "Searchable" : "Unsearchable"}
        state={data?.isSearchable ? statusBadgeStates.active : statusBadgeStates.disabled}
      />
    ),
    sortKey: "isSearchable",
    disableDrag: true,
    style: { minWidth: "87px" }
  },
]
