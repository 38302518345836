import React, { useState } from "react";
import { Button } from "@mui/material";
import { useSelector } from "react-redux";

import { User } from "services/User";

import StyledTabs from "components/StyledTabs";
import ConfirmModal from "components/ConfirmModal";
import StyledTooltip from "components/StyledTooltip";
import UserDatasetsModal from "components/UserDatasetsModal";
import UserSessionsModal from "components/UserSessionsModal";
import ChangeAccessModal from "components/ChangeAccessModal";
import ChangeGeneralModal from "components/ChangeGeneralModal";

import "./styles.scss";


const UserDetails = ({ data, onClose, getData }) => {
  const datasets = useSelector(store => store.datasets.data);
  const [activeTab, setActiveTab] = useState("general");
  const [isLoading, setIsLoading] = useState(false);
  const [showUserRemoveConfirmPopover, setShowUserRemoveConfirmPopover] = useState(false);

  const removeUser = async () => {
    try {
      setIsLoading(true);
      await User.deleteUser(data?.user?.id);
      setShowUserRemoveConfirmPopover(null);
      onClose();
      getData();
    } catch (e) {
      console.log(e)
    } finally {
      setIsLoading(false);
    }
  }

  const tabs = [
    {
      value: "general",
      label: "General",
      getIcon: color => null,
      content: <ChangeGeneralModal user={data?.user} getData={getData} />
    },
    {
      value: "access",
      label: "Access",
      getIcon: color => null,
      content: <ChangeAccessModal user={data?.user} getData={getData} />
    },
    {
      value: "datasets",
      label: "Datasets",
      getIcon: color => null,
      content: <UserDatasetsModal user={data?.user} datasetsList={datasets} />
    },
    {
      value: "sessions",
      label: "Sessions",
      getIcon: color => null,
      content: <UserSessionsModal user={data?.user} />
    },
  ];

  const tabContent = tabs.find(it => it.value === activeTab)?.content;

  return (
    <div className="user-details-wrapper">
      <div className="user-header">
        <div className="user-info">
          <div className="user-name">{data?.user?.firstName} {data?.user?.lastName}</div>
          <StyledTooltip isOverflowContent title={data?.user?.id}>
            <div className="user-id">ID: {data?.user?.id}</div>
          </StyledTooltip>
        </div>
        <Button disabled={isLoading} className="remove-btn" onClick={() => setShowUserRemoveConfirmPopover(true)}>Delete user</Button>
      </div>
      <StyledTabs
        value={activeTab}
        onChange={val => setActiveTab(val)}
        tabs={tabs}
      />

      <div className="tab-content-wrapper">
        {tabContent}
      </div>

      <ConfirmModal
        isDelete
        isLoading={isLoading}
        title="Delete User"
        subtitle="Are you sure you want to delete this user?"
        open={showUserRemoveConfirmPopover}
        onClose={() => setShowUserRemoveConfirmPopover(null)}
        onConfirm={removeUser}
      />
    </div>
  )
};

export default UserDetails;
