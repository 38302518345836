import React from "react";

const LimitIcon = ({ color = "var(--main-text)" }) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10 2.86664V4.5333" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M17.5 9.99998H15.8333" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M2.5 9.99998H4.16667" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M15.3 4.70001L13.5333 6.46668" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M4.70001 15.3L6.36668 13.6334" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M15.3 15.3L13.5333 13.5333" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M4.70001 4.70001L6.46668 6.46668" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M15.3033 4.6967C18.2323 7.62563 18.2323 12.3744 15.3033 15.3033C12.3744 18.2322 7.62568 18.2322 4.69676 15.3033C1.76783 12.3744 1.76783 7.62562 4.69676 4.6967C7.62569 1.76777 12.3744 1.76777 15.3033 4.6967" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M8.78917 9.20166V9.20166C8.16 9.83083 8.16 10.8508 8.78917 11.4792L8.79417 11.4842C9.42333 12.1133 10.4433 12.1133 11.0717 11.4842V11.4842L11.0733 11.4808L11.0742 11.4817C11.7833 10.7725 11.7792 9.23833 11.755 8.71166C11.75 8.60416 11.67 8.52333 11.5617 8.51833C11.035 8.49416 9.50083 8.48916 8.79167 9.19916" stroke={color} strokeWidth="1.4991" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default LimitIcon;
