import React from "react";
import { Button } from "@mui/material";


import "./styles.scss";

const ConfirmPopover = ({ onSuccess, onDismiss }) => {
  return (
    <div className="confirm-popover">
      <div className="label">Are you sure?</div>
      <div className="controls">
        <Button className="cancel-btn" onClick={() => onDismiss()}>Cancel</Button>
        <Button className="save-btn" onClick={() => onSuccess()}>Confirm</Button>
      </div>
    </div>
  )
};

export default ConfirmPopover;