import React from "react";
import "./styles.scss";

const SvgIcon = ({ svgString = "", color = "var(--main-text)" }) => {
  if (!svgString) return null;
  return (
    <div
      className="icon-wrapper"
      dangerouslySetInnerHTML={{__html: svgString.replace(/var\(--color\)/g, color)}}
    />
  );
}

export default SvgIcon;